<!-- 骑手明细 -->
<template>
  <div class="cyclerDefiniteDetailed">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-select v-model="accountValue" placeholder="是否结算" @change="handleSelectAccount" clearable>
          <el-option v-for="item in accountOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="typeValue" placeholder="选择类型" @change="handleSelectType" clearable>
          <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-date-picker v-model="dataValue" type="daterange" popper-class="sift-data" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSelectData" clearable
          value-format="yyyy-MM-dd"></el-date-picker>
        <el-input v-model="orderValue" placeholder="搜索订单号" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="shopValue" placeholder="搜索商超名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="schoolValue" placeholder="搜索学校名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="nameValue" placeholder="搜索骑手姓名" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>

      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="studentId" label="学号"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="riderName" label="骑手姓名"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="riderPhone" label="电话号码"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="sex" label="性别">
            <template slot-scope="scope">
              <span>{{ scope.row.sex == 0 ? '女' : (scope.row.sex == 1 ? '男' : '未知') }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="schoolName" label="学校名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="shopName" label="商超名称"></el-table-column>
          <el-table-column min-width="120" prop="type" label="类型" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>
                {{ getTypeText(scope.row.type) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="orderNum" label="订单号"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="deliveryAmount" label="配送费(元)"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="shopAddFreight" label="加价费(元)"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="deductAmount" label="扣款(元)"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="amount" label="结算金额(元)"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="createTime" label="申请时间"></el-table-column>
          <el-table-column min-width="100" show-overflow-tooltip prop="status" label="是否结算">
            <template slot-scope="scope">
              <span>{{ scope.row.status == 0 ? '否' : '是' }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      accountOptions: [{
        value: '1',
        label: '是'
      }, {
        value: '0',
        label: '否'
      },],
      typeOptions: [{
        value: '1',
        label: '订单配送费'
      }, {
        value: '2',
        label: '差评扣款'
      },
      {
        value: '3',
        label: '超时扣款'
      }, {
        value: '4',
        label: '取消扣款'
      },
      ],
      accountValue: '', // 是否结算
      typeValue: '', // 类型
      nameValue: '', // 搜索骑手姓名
      schoolValue: '', // 搜索学校名称
      shopValue: '', // 搜索商超名称
      orderValue: '', // 搜索订单号
      dataValue: '', // 时间
      // 表格数据
      tableData: [],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getRiderInfoList();
  },
  methods: {
    // 获取列表
    getRiderInfoList() {
      this.loading = true;
      let params = {
        riderName: this.nameValue || null, // 骑手姓名
        status: this.accountValue || null, // 结算状态
        orderNum: this.orderValue || null, // 订单编号
        shopName: this.shopValue || null, // 商超名称
        schoolName: this.schoolValue || null, // 学校名称
        type: this.typeValue || null, // 类型
        page: this.currentPage,
        pageSize: this.pageSize,
      };
      // 时间区间（开始时间-结束时间）
      if (this.dataValue && this.dataValue.length === 2) {
        params.startTime = this.dataValue[0];
        params.endTime = this.dataValue[1];
      } else {
        params.startTime = null;
        params.endTime = null;
      }

      this.$axios.get(this.$api.getRiderInfoList, {
        params
      })
        .then((res) => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list;
            this.totalItemsCount = res.data.result.totalCount;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    handleSelectAccount(value) {
      this.accountValue = value;
      this.onSearch();
    },
    handleSelectData(value) {
      this.dataValue = value;
      this.onSearch();
    },
    handleSelectType(value) {
      this.typeValue = value;
      this.onSearch();
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getRiderInfoList();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.pageSize = e;
      this.onSearch();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getRiderInfoList();
    },
    getTypeText(status) {
      switch (String(status)) {
        case '1':
          return '订单配送费';
        case '2':
          return '差评扣款';
        case '3':
          return '超时扣款';
        case '4':
          return '取消扣款';
        default:
          return '';
      }
    },
  }

}
</script>

<style lang="scss" scoped>
.cyclerDefiniteDetailed {

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;

    .el-input {
      width: 180px;
      margin-left: 20px;
      margin-bottom: 20px;
    }

    .el-select {
      margin-left: 20px;
      margin-bottom: 20px;
    }

    ::v-deep .el-date-editor {
      background-color: #f2f2f2;
      border: none;
      border-radius: 10px;
      margin-left: 20px;
      margin-bottom: 20px;
    }

    ::v-deep .el-range-input {
      background-color: #f2f2f2;

    }
  }
}
</style>
